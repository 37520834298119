import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IconButton } from '../IconButton';
import Close from '../../assets/icons/Close';
import { AnimatePresence, motion } from 'framer-motion';
import Pronostix from '../../assets/icons/Pronostix';
import gql from 'graphql-tag';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import {
  FetchMatchAndUserForecastForPanelQuery,
  Forecast_Status_Enum,
  useFetchMatchAndUserForecastForPanelQuery,
  useMakeUserPronostixMutation,
} from '../../generated/graphql';
import clsx from 'clsx';
import { FlagsCode, TeamWithFlag } from '../TeamWithFlag';
import { Form, Formik } from 'formik';
import { NumberInput } from '../NumberInput';
import { useTimeInterval } from '../../hooks/useTimeInterval';
import { addMinutes, intervalToDuration, isBefore, isWithinInterval } from 'date-fns';
import Alarm from '../../assets/icons/Alarm';
import { Button } from '../Button';
import { showToast } from '../Toast';
import { Modal } from '../Modal';
import { useTranslation } from 'react-i18next';

interface ForecastPanelContextType {
  isOpen: boolean;
  close: () => void;
  requestOpenForecastPanel: (matchId: string) => void;
  matchId?: string;
  requestedMatchId?: string;
  confirmOpenNextMatch: () => void;
  setHasUnsavedChange: () => void;
  hasUnsavedChange: boolean;
}

const noopFn = () => {};

const ForecastPanelContext = createContext<ForecastPanelContextType>({
  isOpen: false,
  close: noopFn,
  requestOpenForecastPanel: noopFn,
  confirmOpenNextMatch: noopFn,
  setHasUnsavedChange: noopFn,
  hasUnsavedChange: false,
});

function useFullScreenContext() {
  const context = useContext(ForecastPanelContext);
  if (!context) {
    throw new Error(`ForecastPanel compound components cannot be rendered outside the ForecastPanel component`);
  }
  return context;
}

gql`
  query fetchMatchAndUserForecastForPanel($matchId: uuid!, $profileId: uuid!) {
    matchByPk(id: $matchId) {
      id
      awayScore
      homeScore
      playedAt
      isMatchOver
      forecasts(where: { profileId: { _eq: $profileId } }) {
        profileId
        matchId
        estimatedAway
        estimatedHome
        status
        forecastsStatus {
          value
          forecastStatusPoints {
            points
            forecast_status
          }
        }
      }
      teamAway {
        id
        playerTeam {
          id
          intlKey
        }
      }
      teamHome {
        id
        playerTeam {
          id
          intlKey
        }
      }
    }
  }

  mutation makeUserPronostix($matchId: uuid!, $awayForecast: Int!, $homeForecast: Int!) {
    makeForecast(awayScore: $awayForecast, homeScore: $homeForecast, matchId: $matchId) {
      forecast {
        createdAt
        updatedAt
        estimatedAway
        estimatedHome
        matchId
        status
        profileId
      }
    }
  }
`;

const TriggerForecastPanel = ({
  children,
  matchId,
  setCloseModal,
}: {
  children: ReactNode;
  matchId: string;
  setCloseModal?: () => void;
}): ReactElement => {
  const { requestOpenForecastPanel } = useFullScreenContext();
  if (!children) {
    throw new Error('No component to render in trigger');
  }

  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            onClick: () => {
              requestOpenForecastPanel(matchId);
              if (setCloseModal) {
                setCloseModal();
              }
            },
          });
        }
        return child;
      })}
    </>
  );
};

type PanelPronostixIconsVariants = 'nominal' | 'missed' | 'partial' | 'perfect' | 'nothing';

const PanelPronostixIcon: React.FC<{ variant?: PanelPronostixIconsVariants }> = ({ variant }) => {
  const classes = clsx(
    {
      'text-red-500 shadow-pronostix-drop-red': variant === 'missed',
      'text-blue-500 shadow-pronostix-drop-blue': variant === 'nominal',
      'text-black-500 shadow-pronostix-drop-black': variant === 'nothing',
      'text-orange-500 shadow-pronostix-drop-orange': variant === 'partial',
      'text-green-500 shadow-pronostix-drop-green': variant === 'perfect',
    },
    'transition transform -translate-x-1/2 absolute -top-7  left-1/2 right-auto rounded-full p-3 border-2 border-current bg-white',
  );

  return (
    <>
      {variant !== undefined ? (
        <div key="forecastLayerIcon" className={classes}>
          <Pronostix className="w-8 h-8" />
        </div>
      ) : null}
    </>
  );
};

const PanelContainer: React.FC = ({ children }) => {
  const { close, isOpen } = useFullScreenContext();

  return (
    <AnimatePresence>
      {isOpen ? (
        <motion.div
          className="fixed right-0 bottom-0 left-0 z-20 bg-white rounded-t-3xl shadow-bottom-layer"
          key="forecastLayer"
          initial={{ y: '100vh' }}
          animate={{ y: 0 }}
          exit={{ y: '100vh' }}
          transition={{ ease: 'easeInOut', duration: 0.5 }}
        >
          <motion.div layout className="relative pt-12 md:p-6 lg:pb-10 lg:pt-12">
            <IconButton
              alt="close pronostix layer"
              className="absolute top-4 right-6 md:top-2 md:right-2"
              variant="secondary"
              icon={Close}
              onClick={() => close()}
            />
            {children}
          </motion.div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};

const getPanelIconValueFromData = (data: FetchMatchAndUserForecastForPanelQuery): PanelPronostixIconsVariants => {
  const { matchByPk } = data;
  if (!matchByPk) {
    return 'nominal';
  }
  if (isBefore(new Date(), new Date(matchByPk.playedAt))) {
    return 'nominal';
  }
  if (matchByPk.forecasts?.[0]?.status) {
    switch (matchByPk.forecasts[0].status) {
      case Forecast_Status_Enum.Miss:
        return 'missed';
      case Forecast_Status_Enum.Partial:
        return 'partial';
      case Forecast_Status_Enum.Perfect:
        return 'perfect';
    }
  }
  if (matchByPk.forecasts.length === 0) {
    return 'nothing';
  }
  return 'nominal';
};

const LoadingSkeleton = () => {
  return (
    <div className="grid grid-cols-7 gap-4 animate-pulse">
      <div className="col-span-7 place-self-center h-6 text-2xl font-bold text-black" />
      <div className="col-span-3 place-self-end w-32 h-8 bg-gray-200" />
      <div className="place-self-center font-bold text-gray-500" />
      <div className="col-span-3 place-self-start w-32 h-8 bg-gray-200" />
      <div className="col-span-3 place-self-end w-52 h-12 bg-gray-200" />
      <div className="place-self-center" />
      <div className="col-span-3 col-end-8 place-self-start w-52 h-12 bg-gray-200" />
      <div className="col-span-7 place-self-center h-10"> </div>
    </div>
  );
};

const NumberFakeInput = ({ amount }: { amount: undefined | number }) => {
  return (
    <div className="flex justify-center items-center w-full h-12 font-normal text-black bg-gray-100 rounded-lg border border-gray-300 border-solid select-none max-w-56">
      <div>{amount ?? '-'}</div>
    </div>
  );
};

const PointsIndicator = ({ data }: { data: FetchMatchAndUserForecastForPanelQuery }) => {
  const { t } = useTranslation('forecastPanel');
  const maybePronostix = data.matchByPk?.forecasts?.[0];
  let baseClasses = 'text-black-500';
  if (!maybePronostix?.forecastsStatus && maybePronostix) {
    return (
      <div className="flex flex-col justify-center items-center font-semibold align-middle">
        <svg
          x="0px"
          y="0px"
          width="1em"
          height="1em"
          viewBox="0 0 40 30"
          className="inline-block w-6 h-6 text-blue-500"
        >
          <rect x="10" y="0" width="4" height="10" fill="currentColor">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="translate"
              values="0 0; 0 20; 0 0"
              begin="0"
              dur="0.7s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="20" y="0" width="4" height="10" fill="currentColor">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="translate"
              values="0 0; 0 20; 0 0"
              begin="0.2s"
              dur="0.7s"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="30" y="0" width="4" height="10" fill="currentColor">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="translate"
              values="0 0; 0 20; 0 0"
              begin="0.4s"
              dur="0.7s"
              repeatCount="indefinite"
            />
          </rect>
        </svg>
      </div>
    );
  }
  if (maybePronostix?.forecastsStatus) {
    switch (maybePronostix.status) {
      case Forecast_Status_Enum.Miss:
        baseClasses = 'text-red-500';
        break;
      case Forecast_Status_Enum.Partial:
        baseClasses = 'text-orange-500';
        break;
      case Forecast_Status_Enum.Perfect:
        baseClasses = 'text-green-500';
        break;
    }
  }
  const points = maybePronostix?.forecastsStatus?.forecastStatusPoints?.points ?? 0;

  return (
    <div className={`flex justify-center items-center align-middle flex-col font-semibold ${baseClasses}`}>
      <Pronostix className="w-4 h-4" />
      <div>
        {points > 0 ? '+ ' : ''}
        {t('forecastPanel:point', { count: points })}
      </div>
    </div>
  );
};

interface PanelFormValues {
  homeScore: number;
  awayScore: number;
}

const Panel = (): ReactElement => {
  const {
    isOpen,
    matchId,
    close,
    setHasUnsavedChange,
    requestedMatchId,
    confirmOpenNextMatch,
  } = useFullScreenContext();
  const { t } = useTranslation(['forecastPanel', 'common']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { profile } = useCurrentUser();
  const [{ fetching, data, error }, fetchMatchAndUserForPanel] = useFetchMatchAndUserForecastForPanelQuery({
    pause: !isOpen,
    requestPolicy: 'network-only',
    variables: {
      profileId: profile?.id ?? '',
      matchId: matchId ?? '',
    },
  });
  useEffect(() => {
    if (!fetching && isOpen && matchId) {
      const id = setTimeout(() => fetchMatchAndUserForPanel({ requestPolicy: 'network-only' }), 10000);
      return () => clearTimeout(id);
    }
  }, [fetching, fetchMatchAndUserForPanel, matchId, isOpen]);

  const timeInterval = useTimeInterval(1);
  const [, makeUserForecast] = useMakeUserPronostixMutation();

  useEffect(() => {
    setIsSubmitting(false);
  }, [matchId]);

  const defaultFormValue = useMemo<PanelFormValues>(() => {
    const maybeForecast = data?.matchByPk?.forecasts?.[0];
    if (!maybeForecast) {
      return {
        awayScore: 0,
        homeScore: 0,
      };
    }
    return {
      awayScore: maybeForecast?.estimatedAway ?? 0,
      homeScore: maybeForecast?.estimatedHome ?? 0,
    };
  }, [data]);

  const onSubmit = useCallback(
    async (values: PanelFormValues) => {
      setIsSubmitting(true);
      const { error } = await makeUserForecast({
        matchId,
        awayForecast: values.awayScore,
        homeForecast: values.homeScore,
      });
      if (error?.message) {
        if (error.message === '[GraphQL] deadline-exceeded') {
          showToast({
            title: t('forecastPanel:toast.tooLate.title'),
            description: t('forecastPanel:toast.tooLate.body'),
            type: 'error',
          });
        } else {
          showToast({
            title: t('common:toast.genericErrorTitle'),
            description: t('forecastPanel:toast.error.body'),
            type: 'error',
          });
        }
      } else {
        showToast({
          title: t('forecastPanel:toast.success.title'),
          description: t('forecastPanel:toast.success.body'),
          type: 'success',
        });
        close();
      }
      setIsSubmitting(false);
      // console.log(error?.message);
    },
    [matchId, close, makeUserForecast, t],
  );
  const isFetchingNewMatch = (fetching && !data) || (fetching && data?.matchByPk?.id !== matchId);

  const variantIcon = useMemo<PanelPronostixIconsVariants | undefined>(() => {
    if (error || isFetchingNewMatch || !data) {
      return undefined;
    }
    return getPanelIconValueFromData(data);
    // Here we have to do this, since we use the timeInterval to force update the function every second
    // Since it's a time relegated issue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, fetching, data, timeInterval]);

  if (!profile) {
    return <div />;
  }
  if (!data?.matchByPk) {
    return <div />;
  }

  const match = data.matchByPk;
  const userForecast = data.matchByPk.forecasts?.[0];

  const matchDate = new Date(match?.playedAt);
  const now = new Date();

  const isWithin5LessMinutes = isWithinInterval(matchDate, { start: now, end: addMinutes(now, 5) });
  const isMatchStarted = isBefore(matchDate, now);

  let heroLabel = t('forecastPanel:hero.open'); //
  let middleBlock = <div />;
  let homeForecastBlock = <NumberInput disabled={isSubmitting} name="homeScore" type="text" />;
  let awayForecastBlock = <NumberInput disabled={isSubmitting} name="awayScore" type="text" />;

  if (isWithin5LessMinutes) {
    const { minutes, seconds } = intervalToDuration({ start: now, end: matchDate });
    middleBlock = (
      <div className="flex justify-center items-center">
        <Alarm className="mr-1 w-4 h-4 text-red-500" />
        <span className="font-bold">
          {`${minutes}`.padStart(2, '0')}:{`${seconds}`.padStart(2, '0')}
        </span>
      </div>
    );
  }

  if ((variantIcon !== undefined && variantIcon !== 'nominal') || match?.isMatchOver) {
    middleBlock = <PointsIndicator data={data} />;
  }
  if (isMatchStarted) {
    homeForecastBlock = <NumberFakeInput amount={userForecast?.estimatedHome ?? undefined} />;
    awayForecastBlock = <NumberFakeInput amount={userForecast?.estimatedAway ?? undefined} />;
    heroLabel = userForecast
      ? t('forecastPanel:hero.started.forecastMade')
      : t('forecastPanel:hero.started.noForecast');
  }
  if (match?.isMatchOver) {
    if (!userForecast) {
      heroLabel = t('forecastPanel:hero.finished.noForecast');
    } else {
      if (!userForecast.status) {
        heroLabel = t('forecastPanel:hero.finished.calculating');
      } else {
        switch (userForecast.status) {
          case Forecast_Status_Enum.Perfect:
            heroLabel = t('forecastPanel:hero.finished.perfect');
            break;
          case Forecast_Status_Enum.Partial:
            heroLabel = t('forecastPanel:hero.finished.partial');
            break;
          case Forecast_Status_Enum.Miss:
            heroLabel = t('forecastPanel:hero.finished.missed');
            break;
        }
      }
    }
  }

  const content = (
    <div className="grid grid-cols-4 gap-4 md:grid-cols-7">
      <div className="col-span-full place-self-center text-2xl font-bold text-black">{heroLabel}</div>
      <TeamWithFlag
        className="col-span-2 row-start-2 self-center place-self-end md:col-span-3 md:place-self-end md:self-auto"
        teamCode={(match?.teamHome?.playerTeam.intlKey as FlagsCode) ?? 'UNKNOWN'}
        variant="regular"
      />
      <div className="col-span-full row-start-3 place-self-center text-xl font-bold text-gray-500 md:col-start-auto md:col-span-1 md:row-start-auto">
        {isMatchStarted ? (
          <div className="flex space-x-2">
            <div className="text-black">{match?.homeScore ?? 0}</div>
            <div>:</div>
            <div className="text-black">{match?.awayScore ?? 0}</div>
          </div>
        ) : (
          t('forecastPanel:versus')
        )}
      </div>
      <TeamWithFlag
        className="col-span-1 col-start-3 row-start-4 self-center place-self-start md:flex-row-reverse md:col-span-3 md:place-self-start md:row-auto"
        teamCode={(match?.teamAway?.playerTeam.intlKey as FlagsCode) ?? 'UNKNOWN'}
        variant="regular"
        reverse
      />
      <div className="col-span-2 row-start-2 md:row-start-auto md:w-full md:col-span-3 md:place-self-end md:flex md:justify-end">
        {homeForecastBlock}
      </div>
      <div className="col-span-full row-start-5 place-self-center md:col-span-1 md:row-start-auto">{middleBlock}</div>
      <div className="col-span-2 row-start-4 flex justify-end md:col-span-3 md:w-full md:place-self-start md:col-end-8 md:row-auto md:justify-start">
        {awayForecastBlock}
      </div>
      <div className="col-span-full place-self-center">
        {isMatchStarted ? (
          <Button type="button" onClick={() => close()}>
            {t('forecastPanel:actions.close')}
          </Button>
        ) : (
          <div className="flex space-x-2">
            <Button type="button" variant="secondary" disabled={isSubmitting} onClick={() => close()}>
              {t('forecastPanel:actions.cancel')}
            </Button>
            <Button disabled={isSubmitting} type="submit">
              {t('forecastPanel:actions.doForecast')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <PanelContainer>
      <PanelPronostixIcon variant={variantIcon} />
      <AnimatePresence>
        {isFetchingNewMatch ? (
          <LoadingSkeleton />
        ) : (
          <div className="m-8">
            {isMatchStarted ? (
              content
            ) : (
              <Formik
                initialValues={defaultFormValue}
                enableReinitialize={true}
                validate={() => {
                  setHasUnsavedChange();
                }}
                onSubmit={onSubmit}
                validateOnBlur
              >
                {({ values }) => (
                  <Form>
                    {content}
                    <Modal
                      isOpen={requestedMatchId !== undefined}
                      title={t('forecastPanel:modal.title')}
                      onClose={() => confirmOpenNextMatch()}
                    >
                      <div>
                        <div className="grid grid-cols-7 gap-4 mb-10">
                          <TeamWithFlag
                            className="col-span-3 place-self-end"
                            teamCode={(match?.teamHome?.playerTeam.intlKey as FlagsCode) ?? 'UNKNOWN'}
                            variant="regular"
                            numberToDisplay={values.homeScore}
                          />
                          <div className="place-self-center text-xl font-bold text-gray-500">vs</div>
                          <TeamWithFlag
                            className="col-span-3 place-self-start"
                            numberToDisplay={values.awayScore}
                            teamCode={(match?.teamAway?.playerTeam.intlKey as FlagsCode) ?? 'UNKNOWN'}
                            variant="regular"
                            reverse
                          />
                        </div>
                        <div className="flex justify-end">
                          <Button variant="secondary" onClick={() => confirmOpenNextMatch()}>
                            {t('forecastPanel:modal.actions.noSave')}
                          </Button>
                          <Button
                            className="ml-2"
                            variant="primary"
                            onClick={async () => {
                              try {
                                await onSubmit(values);
                              } finally {
                                confirmOpenNextMatch();
                              }
                            }}
                          >
                            {t('forecastPanel:modal.actions.save')}
                          </Button>
                        </div>
                      </div>
                    </Modal>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        )}
      </AnimatePresence>
    </PanelContainer>
  );
};

export const ForecastPanel = ({ children }: { children: ReactNode }): ReactElement => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [hasUnsavedChange, setHasUnsavedChange] = React.useState(false);
  const [matchId, setMatchId] = React.useState<undefined | string>(undefined);

  const [requestedMatchId, setRequestedMatchId] = React.useState<undefined | string>(undefined);

  const setHasUnsavedChangeToTrue = useCallback(() => {
    setHasUnsavedChange(true);
  }, []);

  const close = React.useCallback(() => {
    setIsOpen(false);
    setHasUnsavedChange(false);
    setRequestedMatchId(undefined);
  }, []);

  const requestOpenForecastPanel = useCallback(
    (matchId: string) => {
      if (hasUnsavedChange) {
        setRequestedMatchId(matchId);
        return;
      }

      setHasUnsavedChange(false);
      setMatchId(matchId);
      if (!isOpen) {
        setIsOpen(true);
        return;
      }
    },
    [hasUnsavedChange, isOpen],
  );

  const confirmOpenNextMatch = useCallback(() => {
    setHasUnsavedChange(false);
    setMatchId(requestedMatchId);
    setIsOpen(true);
    setRequestedMatchId(undefined);
  }, [requestedMatchId]);

  const value = React.useMemo<ForecastPanelContextType>(
    () => ({
      isOpen,
      setHasUnsavedChange: setHasUnsavedChangeToTrue,
      matchId,
      close,
      hasUnsavedChange,
      requestOpenForecastPanel,
      requestedMatchId: requestedMatchId,
      confirmOpenNextMatch,
    }),
    [
      isOpen,
      setHasUnsavedChangeToTrue,
      matchId,
      close,
      hasUnsavedChange,
      requestOpenForecastPanel,
      requestedMatchId,
      confirmOpenNextMatch,
    ],
  );

  return <ForecastPanelContext.Provider value={value}>{children}</ForecastPanelContext.Provider>;
};

ForecastPanel.TriggerForecastPanel = TriggerForecastPanel;
ForecastPanel.Panel = Panel;
