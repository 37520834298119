import { useEffect, useState } from 'react';
export var useTimeInterval = function useTimeInterval(seconds) {
  var _useState = useState(0),
      secs = _useState[0],
      setSeconds = _useState[1];

  useEffect(function () {
    var interval = setInterval(function () {
      setSeconds(function (secs) {
        return secs + 1;
      });
    }, seconds * 1000);
    return function () {
      return clearInterval(interval);
    };
  }, [seconds]);
  return secs;
};