import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Close from '../../assets/icons/Close';

export interface ModalProps {
  isOpen: boolean;
  title?: string;
  onClose: () => void;
  isEmptyTemplate?: boolean;
  children: React.ReactNode;
}

export const Modal = ({ children, isOpen, title, onClose, isEmptyTemplate = false }: ModalProps) => {
  // let closeButtonRef = useRef(null);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={isOpen} onClose={onClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {!isEmptyTemplate ? (
              <div className="inline-block bg-white w-full max-w-150 rounded-lg p-6 text-left overflow-hidden shadow-xl transform transition-all align-middle">
                <div className="bg-white rounded-lg w-full">
                  <div className="flex justify-between mb-6">
                    <div className="text-2xl font-black text-black">{title}</div>
                    <Close onClick={onClose} className="ml-4 cursor-pointer text-gray-500 hover:text-black w-5 h-5" />
                  </div>
                  {children}
                </div>
              </div>
            ) : (
              <div className="inline-block bg-white rounded-lg w-150 text-left overflow-hidden shadow-xl transform transition-all align-middle relative">
                <Close
                  onClick={onClose}
                  className="absolute top-6 right-6 cursor-pointer text-gray-500 hover:text-black w-5 h-5"
                />
                {children}
                <button className="opacity-0 absolute bottom-0"></button>
              </div>
            )}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  function setOpen() {
    setIsOpen(true);
  }

  function setClose() {
    setIsOpen(false);
  }

  useEffect(() => {
    function handleKeyPress(event: { key: string }) {
      if (event.key === 'Escape') {
        setClose();
      }
    }

    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [isOpen]);

  return [isOpen, setOpen, setClose, setIsOpen] as const;
};
